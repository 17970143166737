@mixin flatten-list($excludeLi:false) {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    @if $excludeLi == false {
      display: inline-block;
    }
  }
}
