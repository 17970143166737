.plf-reservation-choose-stop {
  $icon-size: 40px;

  .accordion-trigger {
    display: none;

    &:checked {
      .accordion-panel {
        display: block;
      }

      .accordion-trigger-label .accordion-title {
        background: none;
        border: 1px solid $color-gray-light;
        border-bottom: 0;
        padding: 30px - 1px; //- border size
        position: relative;

        &::before {
          background-color: $color-gray-light;
          bottom: 0;
          content: '';
          display: block;
          height: 1px;
          left: 30px;
          position: absolute;
          right: 30px;
          width: calc(100% - (#{30px * 2}));
        }

        .accordion-icon img {
          &.opened {
            display: inline;
          }

          &.closed {
            display: none;
          }
        }
      }
    }
  }

  .accordion-panel {
    display: none;
  }

  .accordion-trigger-label {
    display: block;
    margin: 0;
  }

  .accordion-title {
    background-color: $color-gray-light;
    padding: 30px;
    width: 100%;

    .accordion-title-inner {
      width: calc(100% - #{$icon-size});
    }

    .accordion-icon {
      display: inline-block;
      float: right;
      height: $icon-size;
      text-align: center;
      width: $icon-size;

      &,
      i {
        line-height: $icon-size;
      }
    }

    h2 {
      line-height: $icon-size;
      margin: 0;
    }

    &::before {
      background-color: $color-gray-light;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 30px;
      position: absolute;
      right: 30px;
      width: calc(100% - (60px));
    }
  }

  .plf-accordion-item {
    margin-bottom: 22px;

    &.plf-accordion-open {
      .plf-accordion-trigger {
        &::before {
          content: '\e905';
        }
      }
    }

    .plf-accordion-trigger {
      background-color: $color-sbb-gray-light;
      border: 0;
      border-bottom: 1px solid $color-white;
      display: block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      padding: 12px;
      position: relative;
      text-transform: uppercase;
      width: 100%;

      &::before {
        color: $color-sbb-red;

        content: '\e901';
        float: right;

        font-family: 'icomoon';
        font-weight: normal;
      }

      .plf-accordion-trigger-text {
        padding-left: 40px;
      }

      .icon-kartenansicht,
      .icon-listenansicht {
        font-size: 24px;
        margin-top: -4px;
        position: absolute;
      }

    }

  }

  .plf-choose-stop-legend-wrapper {
    .plf-choose-reset {
      float: right;

      text-decoration: underline;
    }

    .plf-choose-stop-legend {
      display: inline-block;
      height: 100%;
      line-height: 1;
      margin-right: 15px;
      vertical-align: middle;

    }
  }
}
