@charset "utf-8";

//--------------------------------
// Storybook
//--------------------------------
@import '@sbb/ui/dist/css/index';
@import '@sbb/ui/dist/scss/variables';

//--------------------------------
// Settings
//--------------------------------
@import 'settings/settings';
@import 'settings/sbb-settings';
@import 'settings/sbb-grid';
@import 'settings/zIndex';
@import 'settings/colors';
@import 'settings/sbb-colors';
@import 'settings/calendar';

//--------------------------------
// Mixins
//--------------------------------
@import 'mixins/list';
@import 'mixins/alignment';
@import 'mixins/more-info-button';
@import 'mixins/more-info-button-previous';
@import 'mixins/sticky-footer';

//--------------------------------
// Fonts
//--------------------------------
@import 'fonts/fonts';

//--------------------------------
// Bootstrap
//--------------------------------
@import '~bootstrap-sass/assets/stylesheets/bootstrap-sprockets';
// Core variables and mixins
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';
// Reset and dependencies
@import '~bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/print';
// Core CSS
@import '~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/code';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/media';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/component-animations';
// Utility classes
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

html {
  font-size: initial;
}

body {
  font-family: $sbb !important;
  font-size: 16px;
}

.sv-view-wrapper {
  .plf-content p {
    font-size: medium;
    line-height: 30px;
  }

  //--------------------------------
  // Grid
  //--------------------------------
  @import 'grid';

  //--------------------------------
  // Scaffolding & Layouts
  //--------------------------------
  @import '../templates/util/scaffold/scaffold';
  @import '../templates/layout/view/layout';

  //--------------------------------
  // Components
  //--------------------------------
  @import '../templates/components/header/header';
  @import '../templates/components/sidebar/sidebar';
  @import '../templates/components/footer/footer';
  @import '../templates/components/404';
  @import '../templates/components/checkbox';
  @import '../templates/components/content';
  @import '../templates/components/login';
  @import '../templates/components/spinner';
  @import 'common/calendar';
  @import 'fonts/icomoon';
  @import '../templates/components/content-slots/choose-date';
  @import '../templates/components/content-slots/choose-stop';
  @import '../templates/components/content-slots/list';
  @import '../templates/components/content-slots/survey';
  @import '../templates/components/sbb-standard-button';
  @import '../templates/components/forms/forms';
  @import '../templates/components/maps/map';
}
