$font-path: '../../fonts';

@font-face {
  font-family: 'sbb';
  font-style: normal;
  font-weight: 100;
  src: url($font-path + '/sbb/SBBWeb-UltraLight.eot');
  src: url($font-path + '/sbb/SBBWeb-UltraLight.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-UltraLight.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-UltraLight.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-UltraLight.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-UltraLight.svg#SBBWeb-UltraLight') format('svg');
}

@font-face {
  font-family: 'sbb';
  font-style: normal;
  font-weight: 200;
  src: url($font-path + '/sbb/SBBWeb-Thin.eot');
  src: url($font-path + '/sbb/SBBWeb-Thin.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-Thin.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-Thin.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-Thin.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-Thin.svg#SBBWeb-Thin') format('svg');
}

@font-face {
  font-display: auto;
  font-family: 'sbb';
  font-style: normal;
  font-weight: 300;
  src: url($font-path + '/sbb/SBBWeb-Light.eot');
  src: url($font-path + '/sbb/SBBWeb-Light.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-Light.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-Light.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-Light.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-Light.svg#SBBWeb-Light') format('svg');
}

@font-face {
  font-family: 'sbb';
  font-style: normal;
  font-weight: normal;
  src: url($font-path + '/sbb/SBBWeb-Roman.eot');
  src: url($font-path + '/sbb/SBBWeb-Roman.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-Roman.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-Roman.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-Roman.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-Roman.svg#SBBWeb-Roman') format('svg');
}

@font-face {
  font-family: 'sbb';
  font-style: italic;
  font-weight: normal;
  src: url($font-path + '/sbb/SBBWeb-Italic.eot');
  src: url($font-path + '/sbb/SBBWeb-Italic.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-Italic.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-Italic.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-Italic.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-Italic.svg#SBBWeb-Italic') format('svg');
}


@font-face {
  font-family: 'sbb';
  font-style: normal;
  font-weight: bold;
  src: url($font-path + '/sbb/SBBWeb-Bold.eot');
  src: url($font-path + '/sbb/SBBWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbb/SBBWeb-Bold.woff2') format('woff2'),
    url($font-path + '/sbb/SBBWeb-Bold.woff') format('woff'),
    url($font-path + '/sbb/SBBWeb-Bold.ttf') format('truetype'),
    url($font-path + '/sbb/SBBWeb-Bold.svg#SBBWeb-Bold') format('svg');
}
