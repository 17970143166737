footer .plf-up-outer-wrapper {
  height: 75px;

  //zindex
  position: relative;
  z-index: z-index(sbbFooterUp);
}

footer .plf-up-wrapper {
  transition: bottom 0.3s ease;

  &[data-fixed='true'] {
    bottom: 75px;
    position: fixed;
    width: 100%;
  }

  .plf-up {
    height: 0;
    text-align: right;

    .plf-up-trigger {
      background: none;
      border: 0;
      outline: 0;
      padding: 0;

      span {
        font-size: 18px;
      }

      i {
        font-size: 35px;
        line-height: 100%;
        position: relative;
        right: -10px;
        top: 12px;
      }

      &:hover {
        span,
        i {
          color: $color-red-dark;
        }
      }
    }
  }
}
