.plf-sidebar {
  background-color: $color-white;
  bottom: 0;
  left: 0;
  position: fixed;
  top: $header-height-mobile;
  transform: translateX(0%);

  transition: transform 0.8s ease;
  width: 27%;
  z-index: z-index(sbbSidebar);

  @media (max-width:680px) {
    width: 52%;
  }

  &::after {
    background-color: rgba($color-black, 0.15);
    bottom: 0;
    content: ' ';
    height: 100%;
    left: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 8px;
  }

  //sidebar closed
  &[data-open='false'] {
    transform: translateX(-100%);

    &::after {
      opacity: 0;
    }
  }
}

//overlay
.plf-sidebar-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: z-index(sbbSidebarOverlay);

  &[data-open='false'] {
    display: none;
  }
}

//content
.plf-sidebar {
  $sidebar-padding: 24px;
  @include sticky-footer('.plf-sidebar-menu-wrapper');
  min-height: 0 !important; //counter min-height:100% from mixin (not needed here)

  .plf-sidebar-menu-wrapper,
  .plf-sidebar-metanav-wrapper {
    @media (max-width: $screen-xs-max) {
      padding: 15px $grid-view-port-spacing-x-small;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      padding: 15px $grid-view-port-spacing-small;
    }

    @media (min-width: $screen-sml-min) and (max-width: $screen-sml-max) {
      $unitless: $grid-viewport-spacing-small-landscape / ($grid-viewport-spacing-small-landscape * 0 + 1);
      padding: 35px calc(100vw / 100 * #{$unitless});
    }

    ul {
      @include flatten-list(true);
    }
  }

  .plf-sidebar-menu-wrapper {
    li {
      display: block;

      &,
      i,
      a,
      span {
        font-size: 16px;
        line-height: 35px;
        text-decoration: none;
      }

      &[data-active='true'] {
        &,
        i,
        a,
        span {
          text-decoration: underline;
        }
      }
    }
  }

  .plf-sidebar-metanav-wrapper {
    li {
      display: block;

      &,
      i,
      a,
      span {
        color: $color-gray;
        font-size: 14px;
        line-height: 29px;
        text-decoration: none;
      }
    }
  }
}
