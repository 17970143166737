@mixin sticky-footer($content-selector: '*:nth-child(1)', $footer-selector: '*:nth-child(2)') {

  // load flex if browser can't handle grid
  @supports not (grid-template-areas:'support') {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    #{$content-selector} {
      -webkit-box-flex: 1;
      flex: 1;
    }
  }

  // load grid if browser supports it
  @supports (grid-template-areas:'support') {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100%;

    #{$footer-selector} {
      grid-row-end: 3;
      grid-row-start: 2;
    }
  }

}
