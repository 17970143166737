footer .plf-footer-bottom-content {
  div {
    @include valign;
    height: 100%;
    width: 100%;
  }

  ul {
    @include flatten-list;
    text-align: right;
    width: 100%;

    li {
      margin-right: 50px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        color: $color-gray;
        font-size: 12px;
        text-decoration: none;

        &:hover {
          color: $color-red-dark;
        }
      }
    }
  }
}
