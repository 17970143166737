//colors
$color-white: #fff;
$color-white-transparent: rgba($color-white, 0.45);
$color-gray-lighter: #f7f7f7;
$color-gray-light: #e5e5e5;
$color-gray-medium: #cdcdcd;
$color-almost-gray: #a8a8a8;
$color-gray: #767676;
$color-gray-almost-dark: #666;
$color-gray-dark: #444;
$color-black: #000;
$color-red-light: #c90009;
$color-red: #eb0000;
$color-red-dark: #c60018;
$color-red-very-dark: #a40008;
$color-ctrl-hover: #a20013;
$color-red-personalia: #d70000;
$color-ctrl-border: #b7b7b7;
$color-green: #24a657;

//dimensions
$header-height: 125px;
$header-height-mobile: 50px;
$footer-main-height: 375px;
$footer-main-fixed-height: 40px;
$footer-bottom-height: 95px;
