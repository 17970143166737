@import 'search';

.plf-header .plf-header-actions {
  padding-right: 50px;

  ul {
    @include flatten-list(true);
    @include valign;
    float: right;
    height: 100%;

    li {
      padding-left: 10px;

      label {
        @include valign;
        margin-bottom: 0;
      }

      //dirty fix alignment of icons
      i {
        position: relative;
        top: 3px;
      }

      &,
      i,
      a,
      span {
        color: $color-gray;
      }

      &:hover {
        &,
        i,
        a,
        span {
          color: $color-red-dark;
          cursor: pointer;
        }
      }
    }
  }

  //mobile
  @media (max-width: $screen-sm-max) {
    ul {
      float: right;
    }

    ul li span {
      display: none;
    }
  }
}
