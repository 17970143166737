$font-path: '../../fonts';

//-------------------------------------------------------------------
// Dimension settings
//-------------------------------------------------------------------
$sidebar-width: 20%;
$sidebar-medium-width: 50%;
$sidebar-small-width: 100%;
$side-margin: rem-calc(100);
$side-margin-medium: rem-calc(15);
$side-margin-small: rem-calc(15);

$max-width: 1280px;

// Font weights
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-default-line-height: 1.42857; // Boostrap line height used to reset to default

// Font types for the site
$font-default-family: $font-primary;
//###################################################################
// FONT SIZES
//###################################################################

// Font sizes for the general elements
//-------------------------------------------------------------------
$font-size-header: rem-calc(20);  // Header
$font-size-footer: rem-calc(16);  // Footer
$font-size-header: rem-calc(17);    // General Text
$font-size-source: rem-calc(14);  // Text source

// Font size for the sidebar
//-------------------------------------------------------------------
$font-size-sidebar: rem-calc(18);
$font-size-menu-titles: rem-calc(20);
$font-size-quicklinks: $font-size-sidebar;
$font-size-close: rem-calc(16);

// Font sizes for the article
//-------------------------------------------------------------------
$font-size-lead: rem-calc(22); // Article lead
$font-size-label: rem-calc(22); // Article header label
$font-size-image-desc: $font-size-header - rem-calc(2); // Image description
$font-size-bring-me-up: rem-calc(16); // Page up button
$font-size-quote: $font-size-header + rem-calc(2); // Article Quote
$font-size-anchor: $font-size-header - rem-calc(4); // Article Anchor
$font-size-comments: $font-size-header; // Comments text
$font-size-related-title: $font-size-header + rem-calc(4); // Title of the related articles
$font-size-related: $font-size-header;  // Related articles text
$related-text-line-height: 1.4;  // Line height of the related article text
$related-text-lines-to-show: 4; // How many lines of text have to be showed for a related article
$font-size-list-title: $font-size-header + rem-calc(8);
$font-size-list: $font-size-header; // Text of the red list
$font-size-survey: $font-size-header; // Text of the survey
$font-size-survey-title: $font-size-header + rem-calc(8); // Title of the survey

// Font sizes for the dashboard
//-------------------------------------------------------------------
$font-size-dashboard-header: $font-size-header + rem-calc(2); // Dashboard header label text
$font-size-dashboard-header-title: $font-size-header + rem-calc(12); // Dashboard header label title
$font-size-dashboard-header-small: $font-size-header - rem-calc(1) ; // Dashboard header label text
$font-size-dashboard-header-title-small: $font-size-header; // Dashboard header label title
$dashboard-label-line-height: 1.4; // Line height for the header label
$dashboard-label-line-height-small: 1.2; // Line height for the header label
$dashboard-label-line-to-show: 4; // Lines to show on the header label for normal screens
$dashboard-label-line-to-show-medium: 3; // Lines to show on the header label for medium devices
$dashboard-label-line-to-show-small: 3; // Lines to show on the header label for medium devices
$font-size-tile-label: $font-size-header - rem-calc(2); // Tile label
$font-size-tile-text: $font-size-header; // Tile text

// Special page font sizes
//-------------------------------------------------------------------
$font-size-404-title: rem-calc(30);
$font-size-404: rem-calc(18);
$font-size-login-title: rem-calc(30);
$font-size-login: rem-calc(18);
