.plf-reservation-list {
  border-collapse: collapse;
  font-size: 14px;
  table-layout: fixed;
  width: 100%;

  &.column {
    margin-top: 40px;
    padding: 0;
  }

  a {
    &.plf-accordion-trigger {
      display: none;
    }
  }

  &,
  thead,
  tbody {
    border: 0;
  }

  tr,
  td,
  th {
    width: 100%;
  }

  td {
    color: $color-black;
  }

  tr {
    line-height: 50px;

    &:first-of-type {
      background-color: $color-sbb-gray;
    }
  }

  .plf-list-detail {
    .container-fluid {
      margin: 0;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      position: relative;

      .plf-list-detail-image {
        padding: 10px;

        img {
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }

      .plf-list-detail-description {
        min-height: 250px;
        padding-bottom: 20px;
        padding-top: 20px;
        
        p {
          font-size: medium;
          line-height: 30px;
        }

        .plf-reservation-list-choose-stop {
          padding: 0 15px;
        }

        .plf-button.plf-button-big {
          bottom: 0;
          position: absolute;
          //width: calc(100% - 40px);
          width: auto;

          &.plf-disabled {
            padding: 0 10px;

            &:hover {
              background-color: rgba($color-sbb-red, 0.5);
            }

            .plf-button-text {
              line-height: 40px;
              padding-left: 26px;
            }
          }
        }

        .plf-button-text {
          padding-right: 26px;
          white-space: nowrap;
        }

        @media screen and (max-width: $screen-md-max) {
          h2,
          h3,
          h4 {
            margin-top: 0;
          }
        }

        @media screen and (max-width: $screen-sm-max) {
          h2,
          h3,
          h4 {
            margin-top: 25px;
          }
        }
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      .plf-list-detail-image {
        padding-right: 0;
      }

      .row {
        .plf-list-detail-description {
          padding: 0 25px !important;
          width: 100%;

          .plf-button {
            &.plf-button-big {
              margin-top: 10px;
              position: static;
            }
          }
        }
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      .row {
        .plf-list-detail-description {
          padding: 0 10px !important;
        }
      }
    }
  }

  thead tr,
  tr.plf-reservation-list-detail-trigger,
  .plf-list-detail {
    background-color: $color-sbb-light-gray;
  }

  tr.plf-reservation-list-detail-trigger,
  .plf-list-detail {
    border-top: 1px solid $color-white;
  }

  tr.plf-reservation-list-detail-trigger.plf-reservation-list-planned {
    td {
      color: #797979;
    }
  }

  tr.plf-reservation-list-detail-trigger.plf-reservation-list-active {
    background-color: $color-sbb-light-gray;
  }

  thead td,
  tr.plf-reservation-list-detail-trigger td,
  .plf-list-detail {
    padding: 4px 12px;
  }

  .plf-reservation-list-detail-trigger {
    cursor: pointer;
  }

  tr.plf-reservation-list-detail-trigger.plf-active {
    background-color: $color-sbb-light-gray;
    color: $color-white;
  }

  .plf-list-detail-detail {
    padding: 0;
  }

  .plf-reservation-list-choose-stop {
    background-color: $color-sbb-red;
    color: $color-white;
    display: inline-block;
    font-weight: bold;
    margin-top: 1px;
    padding: 15px 10px;
    text-align: center;
    width: 100%;
  }

  .plf-disabled {
    background-color: rgba($color-sbb-red, 0.5);
    color: $color-white;
    cursor: not-allowed;
    display: inline-block;
    margin-top: 1px;
    padding: 12px 10px;
    text-align: center;
  }

  @media screen and (max-width: $screen-xs-max) {
    thead {
      display: none;
    }

    tbody {
      tr,
      td {
        display: block;
        line-height: 30px;
      }

      tr.plf-reservation-list-detail-trigger td,
      td {
        padding-left: 40%;
        position: relative;

        &::before {
          background: $color-sbb-gray;
          content: attr(data-label);
          height: 100%;
          left: 0;
          padding: 4px 10px;
          position: absolute;
          top: 0;
          width: calc(40% - 10px);
        }
      }

      tr.plf-reservation-list-detail td {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}
