// < 421px
//--------------------------------------------------------------------------------------------------
@media (max-width: $screen-xs-max) {
  .container-fluid {
    margin: 0 $grid-view-port-spacing-x-small;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-view-port-spacing-x-small;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-x-small/2;
    margin-right: -$grid-gutter-x-small/2;
  }

  .column {
    padding-left: $grid-gutter-x-small/2;
    padding-right: $grid-gutter-x-small/2;
  }
}

// 421px - 642px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .container-fluid {
    margin: 0 $grid-view-port-spacing-small;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-view-port-spacing-small;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-small/2;
    margin-right: -$grid-gutter-small/2;
  }

  .column {
    padding-left: $grid-gutter-small/2;
    padding-right: $grid-gutter-small/2;
  }
}

// 769px - 1024px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-sml-min) and (max-width: $screen-sml-max) {
  .container-fluid {
    margin: 0 $grid-viewport-spacing-small-landscape;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-spacing-small-landscape;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-small-landscape/2;
    margin-right: -$grid-gutter-small-landscape/2;
  }

  .column {
    padding-left: $grid-gutter-small-landscape/2;
    padding-right: $grid-gutter-small-landscape/2;
  }
}

// 1025px - 1280px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .container-fluid {
    margin: 0 $grid-viewport-spacing-medium;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-spacing-medium;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-medium/2;
    margin-right: -$grid-gutter-medium/2;
  }

  .column {
    padding-left: $grid-gutter-medium/2;
    padding-right: $grid-gutter-medium/2;
  }
}

// 1281px - 1920px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-normal-min) and (max-width: $screen-normal-max) {
  .container-fluid {
    margin: 0 $grid-viewport-spacing-normal;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-spacing-normal;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-normal/2;
    margin-right: -$grid-gutter-normal/2;
  }

  .column {
    padding-left: $grid-gutter-normal/2;
    padding-right: $grid-gutter-normal/2;
  }
}

// 1921px - 2560px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  .container-fluid {
    margin: 0 auto;
    max-width: $grid-viewport-width-large;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-spacing-normal;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-large/2;
    margin-right: -$grid-gutter-large/2;
  }

  .column {
    padding-left: $grid-gutter-large/2;
    padding-right: $grid-gutter-large/2;
  }
}

// 2561px - 3840px
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
  .container-fluid {
    margin: 0 $grid-viewport-spacing-xl-large;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-spacing-xl-large;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-xl-large/2;
    margin-right: -$grid-gutter-xl-large/2;
  }

  .column {
    padding-left: $grid-gutter-xl-large/2;
    padding-right: $grid-gutter-xl-large/2;
  }
}

// 3841px <
//--------------------------------------------------------------------------------------------------
@media (min-width: $screen-xxl-min) {
  .container-fluid {
    margin: 0 auto;
    max-width: $grid-viewport-width-xxl-large;
    padding: 0;
    width: auto;
  }

  .plf-content {
    margin: 0 $grid-viewport-width-xxl-large;

    .container-fluid {
      margin: 0;
    }
  }

  .row {
    margin-left: -$grid-gutter-xxl-large/2;
    margin-right: -$grid-gutter-xxl-large/2;
  }

  .column {
    padding-left: $grid-gutter-xxl-large/2;
    padding-right: $grid-gutter-xxl-large/2;
  }
}

.custom-5-col {
  width: (100% / 5);
}

@media (max-width: $screen-sm-max) {
  .custom-5-col {
    width: 100%;
  }
}
