.plf-header .plf-header-actions ul { position: relative; }
.plf-header .plf-header-actions .plf-search-form .sv-widget { position: static; }

.plf-header .plf-header-actions .plf-search-form {
  label {
    @include valign;
    margin-bottom: 0;
  }

  .plf-header-search-overlay {
    background-color: $color-white;
    height: 48px;
    left: 0;
    min-width: 100px;
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.4s, right 1s ease;
    width: auto;
    z-index: z-index(sbbHeaderSearch);

    input,
    button {
      background: none;
      border: 0;
      box-shadow: none;
      margin: 0;
      outline: 0;
      padding: 0;
    }

    form {
      $button-width: 53px;
      $border-size: 2px;
      display: block;
      height: 100%;
      width: 100%;

      * {
        display: inline-block;
      }

      input {
        height: 100%;
        padding: 0 15px;
        vertical-align: top;
        width: calc(100% - #{$button-width + $border-size});
      }

      button {
        background-color: $color-red;
        height: calc(100% - 4px);
        margin-bottom: $border-size;
        margin-top: $border-size;
        transition: background 0.3s ease;
        width: $button-width;

        &::before {
          border: $border-size solid $color-ctrl-border;
          bottom: 0;
          content: ' ';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -100; //relative, not relevant for zIndex list
        }

        i {
          color: $color-white;
          display: block;
          font-size: 26px;
          margin: 0 auto;
          padding: 0;
          text-align: center;
        }

        &:hover {
          background-color: $color-ctrl-hover;
        }
      }
    }
  }

  .plf-header-search-overlay-trigger {
    display: none;

    &:checked {
      .plf-header-search-overlay {
        opacity: 1;
        right: 0;
        transition: opacity 0.3s 0.1s ease, right 0.4s ease !important;
        z-index: z-index(sbbHeaderSearchActive);
      }

      .plf-header-search-overlay-trigger-label {
        &::before,
        &::after {
          bottom: 0;
          content: ' ';
          height: 100%;
          left: 0;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: z-index(sbbHeaderSearchCloseOverlay);
        }
      }
    }
  }

  @media (max-width:$screen-sm-max) {
    .plf-header-search-overlay {
      left: 0;
      position: fixed;
      right: 0;
      top: $header-height-mobile;
      transform: none;
      width: 100%;
    }

    .plf-header-search-overlay-trigger:checked {
      .plf-header-search-overlay-trigger-label {
        &::before,
        &::after {
          content: none;
        }

        i {
          color: $color-red-dark;

          &::before {
            content: '\2a';
          }
        }
      }

      .plf-header-search-overlay {
        opacity: 1;
        z-index: z-index(sbbHeaderSearch);
      }
    }
  }

}
