header {
  padding-top: $header-height;
  position: relative;

  nav {
    z-index: 200;
  }
}

.plf-header {
  background-color: $color-white;
  border-bottom: 1px solid $color-gray-light;
  height: $header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: height 0.5s ease 0.2s;

  :first-child {
    height: 100%;
  }

  .plf-header-top,
  .plf-header-bottom {
    height: 50%;
  }

  .plf-header-top {
    height: 100%;
  }

  .plf-header-bottom {
    transition-delay: 0.3s;
  }

  i {
    font-size: 28px;
    line-height: 100%;
    padding-right: 5px;
    width: 32px;
  }
}

//collapsed header
.plf-header[data-collapsed='true'] {
  height: $header-height / 2;

  .plf-header-top {
    height: 100%;
  }

  .plf-header-bottom {
    opacity: 0;
    pointer-events: none;
    transition-delay: -0.3s;
    z-index: -100;
  }

  .plf-header-metanav {
    display: none;
  }
}

//non-collapsed header
.plf-header[data-collapsed='false'] {
  .plf-header-collapse-trigger {
    display: none;
  }
}

//mobile
@media (max-width: $screen-sm-max) {
  header {
    padding-top: $header-height-mobile;
  }

  .plf-header {
    height: $header-height-mobile;

    .plf-header-top {
      height: 100%;
    }

    &[data-collapsed='true'] {
      height: $header-height-mobile;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.m-headerActionsDropdownToggle__label {
  margin-top: rem(3);
}

@import 'actions/actions';
@import 'brand/brand';
@import 'metanav/metanav';
@import 'nav/nav';
@import 'collapse-trigger/collapse-trigger';
@import 'sidebar-trigger/sidebar-trigger';
