.plf-calendar-wrapper {
  .plf-calendar-title-wrapper {
    background-color: $color-sbb-light-gray;
    border-left: 1px solid $color-sbb-white;
    border-right: 1px solid $color-sbb-white;
    border-top: 1px solid $color-sbb-white;
    display: table;
    line-height: 50px;
    width: 100%;

    * {
      display: table-cell;
      vertical-align: middle;
    }

    .plf-calendar-button {
      background-color: $color-sbb-light-gray;
      font-size: 26px;
      height: 25px;
      line-height: 25px;
      text-decoration: none;
      transition: background-color 0.2s;
      width: 50px;

      &.plf-calendar-back-button {
        border-right: 1px solid #fff;
      }

      &.plf-calendar-forward-button {
        border-left: 1px solid #fff;
      }

      span {
        display: block;
        text-align: center;
        text-align: -webkit-center;
      }

      /* stylelint-disable-next-line */
      .icon-pfeil_rechts, .icon-pfeil_links {
        color: $color-sbb-white;
        font-size: 13px;
        vertical-align: middle;

        &:hover {
          color: $color-sbb-white;
        }
      }

      &:hover {
        background-color: rgba($color-sbb-light-gray, 0.5);
      }

      &.plf-disabled {
        background-color: $color-sbb-light-gray;
        pointer-events: none;

        .icon-020-pfeil-zurueck-036,
        .icon-020-pfeil-vor-038 {
          color: $color-sbb-dark-gray;
        }
      }
    }

    .plf-calendar-title {
      text-align: center;
    }
  }

  .plf-calendar-body {
    display: block;
    margin-bottom: 35px;
    overflow-x: auto;
    width: 100%;
  }

  table.plf-calendar {
    border-collapse: collapse;
    width: 100%;

    * {
      color: #282828;
    }

    &,
    th,
    td {
      border: 1px solid $color-sbb-white;
      text-align: center;
    }

    thead {
      line-height: 37px;

      tr td {
        background-color: $color-sbb-light-gray;
        padding: 6px 20px;
      }
    }

    tbody {
      tr {
        td {
          height: 60px;

          &:nth-child(1) {
            background-color: $color-sbb-light-gray;
            padding: 6px;
            width: 50px;
          }
        }
      }
    }

    a {
      color: $color-white;
      display: block;
      font-size: 12px;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      * {
        color: $color-white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      * {
        display: block;
        padding: 0 5px;

        * {
          &:not(:first-child) {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .plf-available {
    background-color: $color-sbb-block-available;

    &:hover {
      background-color: lighten($color-sbb-block-available, 10%);
    }
  }

  .plf-unavailable {
    background-color: $color-sbb-block-unavailable;

    @if $is-admin-calendar {
      &:hover {
        background-color: lighten($color-sbb-block-unavailable, 10%);
      }
    }

    &.plf-unconfirmed {
      background-color: $color-sbb-block-unconfirmed;

      @if $is-admin-calendar {
        &:hover {
          background-color: lighten($color-sbb-block-unconfirmed, 10%);
        }
      }
    }
  }

  .plf-active {
    background-color: $color-sbb-block-active;

    &:hover {
      background-color: lighten($color-sbb-block-active, 10%);
    }
  }

  .plf-disabled {
    background: repeating-linear-gradient(45deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.05) 3px,
    rgba(0, 0, 0, 0.08) 3px,
    rgba(0, 0, 0, 0.08) 6px);

    @if $is-admin-calendar {
      &:hover {
        background: repeating-linear-gradient(45deg,
        rgba(0, 0, 0, 0.03),
        rgba(0, 0, 0, 0.03) 3px,
        rgba(0, 0, 0, 0.06) 3px,
        rgba(0, 0, 0, 0.06) 6px);
      }
    }
  }

  @if $is-admin-calendar == false {
    &.plf-unavailable,
    &.plf-disabled {
      cursor: not-allowed;

      * {
        pointer-events: none;
      }
    }
  }

  .plf-calendar-legend-wrapper {
    .plf-calendar-legend {
      display: inline-block;
      height: 100%;
      line-height: 1;
      margin-bottom: 10px;
      margin-right: 15px;
      vertical-align: middle;

      &::before {
        border-radius: 2px;
        content: '';
        display: inline-block;
        height: 25px;
        line-height: 1;
        vertical-align: middle;
        width: 25px;
      }

      &.plf-free::before {
        background-color: $color-sbb-block-available;
      }

      &.plf-booked::before {
        background-color: $color-sbb-block-unavailable;
      }

      &.plf-reserved::before {
        background-color: $color-sbb-block-unconfirmed;
      }

      &.plf-not-reservable::before {
        background: repeating-linear-gradient(45deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05) 3px,
        rgba(0, 0, 0, 0.08) 3px,
        rgba(0, 0, 0, 0.08) 6px);
      }

      &.plf-selection::before {
        background-color: $color-sbb-block-selection;
      }
    }
  }

  .plf-choose-stop-legend-wrapper {
    .plf-choose-reset {
      float: right;
    }

    .plf-choose-stop-legend {
      display: inline-block;
      height: 100%;
      line-height: 1;
      margin-right: 15px;
      vertical-align: middle;

      &::before {
        content: '';
        display: inline-block;
        height: 25px;
        line-height: 1;
        vertical-align: middle;
        width: 25px;
      }

      &.plf-free::before {
        $marker: '../../img/marker_gray.png';
        background: url($marker);
      }

      &.plf-planned::before {
        background: $color-sbb-red;
      }
    }
  }
}
