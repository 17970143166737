.plf-reservation-map {
  height: 100%;
  min-height: 500px;
  padding: 0;
  width: 100%;

  @media (min-width: $screen-normal-min) {
    min-height: 720px;
  }

  .plf-trafimage {
    height: 60vw;
    min-height: 500px;
    width: 100%;

    @media (min-width: $screen-normal-min) {
      height: 60vh;
      min-height: 720px;
    }

    .tm-schulzug {
      text-align: center;
    }

    .tm-app {
      height: 60vw;
      min-height: 500px;
      overflow: hidden;
      position: absolute;
      width: 100%;

      @media (min-width: $screen-normal-min) {
        height: 60vh;
        min-height: 720px;
      }
    }

    .tm-map {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .plf-map-info-window {
    background: rgba(255, 255, 255, 01);
    box-shadow:
      -0.26667em 0 0 rgba(0, 0, 0, 0.15),
      inset 0 -0.06667em 0 #dcdcdc;
    display: none;
    height: 550px;
    max-height: calc(100% - 5px);
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;

    .plf-map-info-wrapper {
      box-shadow: 0 0 10px rgba($color-sbb-gray, 0.5);
      height: 100%;
      position: relative;

      .plf-map-info-close {
        background: $color-white;
        border: 0;
        border-radius: 20px;
        font-size: 20px;
        padding: 2px;
        position: absolute;
        right: 10px;
        top: 10px;

        i {
          color: $color-black;
        }

        &:hover i,
        &:focus-visible i {
          color: $color-sbb-red;
        }
      }

      .plf-map-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 0;

        img {
          border-bottom: 3px solid rgba(#dcdcdc, 0.8);
          max-width: 100%;
          width: 100%;
        }

        .plf-map-info-container {
          padding: 20px 20px 10px;

          @media (min-width: $screen-normal-min) {
            padding: 20px;
          }

          .plf-map-info-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            h3 {
              margin: 0;
            }

            &__language {
              padding-top: 5px;
            }
          }

          .plf-map-info-week {
            line-height: 1;
            margin-bottom: 5px;
          }

          .plf-map-info-date {
            margin-bottom: 15px;
          }

          .plf-map-info-desc {
            p {
              font-size: 14px;
              font-weight: $font-weight-normal;
              line-height: 1.4;

              @media (min-width: $screen-normal-min) {
                line-height: 1.7;
              }
            }
          }
        }

        &__button-wrapper {
          padding: 10px 20px;

          .plf-button-big {
            margin-bottom: 5px;

            &.plf-disabled {
              background-color: rgba($color-sbb-red, 0.5);
              cursor: not-allowed;
              padding: 12px;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
