// < 421px
$screen-xs-max: 420px;
$grid-gutter-x-small: 12px;
$grid-view-port-spacing-x-small: $grid-gutter-x-small;

// 421px - 642px
$screen-sm-min: 421px;
$screen-sm-max: 642px;
$grid-gutter-small: 24px;
$grid-view-port-spacing-small: $grid-gutter-small;

// 643px - 1024px
$screen-sml-min: 643px;
$screen-sml-max: 1024px;
$grid-gutter-small-landscape: 16px;
$grid-viewport-spacing-small-landscape: 6.5%;

// 1025px - 1280px
$screen-md-min: 1025px;
$screen-md-max: 1280px;
$grid-gutter-medium: 16px;
$grid-viewport-spacing-medium: 7.5%;


// 1281px - 1920px
$screen-normal-min: 1281px;
$screen-normal-max: 1920px;
$grid-gutter-normal: 16px;
$grid-viewport-spacing-normal: 8.5%;

// 1921px - 2560px
$screen-lg-min: 1921px;
$screen-lg-max: 2560px;
$grid-gutter-large: 16px;
$grid-viewport-width-large: 1592px;

// 2561px - 3840px
$screen-xl-min: 2561px;
$screen-xl-max: 3840px;
$grid-gutter-xl-large: 24px;
$grid-viewport-spacing-xl-large: 19%;

// 3841px <
$screen-xxl-min: 3841px;
$grid-gutter-xxl-large: 32px;
$grid-viewport-width-xxl-large: 2656px;

// Breakpoints
$screen-xs-max: $screen-xs-max;
$screen-sm-min: $screen-sm-min;
$screen-sm-max: 980px;
$screen-md-min: 981px;
$screen-md-max: $screen-normal-max;
$screen-lg-min: $screen-lg-min;
