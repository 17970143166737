.plf-reservation-choose-date {
  .plf-reservation-stop-description {
    background-color: transparent;
  }

  .plf-calendar-wrapper.timeslot-selected {
    .plf-calendar-wrapper-content {
      width: 80%;
    }
  }

  .plf-calendar-wrapper-content {
    float: left;
    transition: 300ms ease-in-out width;
    width: 100%;
  }

  .plf-reservation-current-time-slot-wrapper {
    float: left;
    margin-right: -100%;
    opacity: 0;
    transition: 300ms ease-in-out margin-right, 300ms ease-in-out opacity;
    width: 20%;

    &.timeslot-selected {
      margin-right: 0;
      opacity: 1;
    }
  }

  @media (max-width: $screen-sm-max) {
    .plf-calendar-wrapper-content {
      float: none;
    }

    .plf-calendar-wrapper.timeslot-selected {
      .plf-calendar-wrapper-content {
        width: 100%;
      }
    }

    .plf-reservation-current-time-slot-wrapper {
      float: none;
      margin-right: 0;
      width: 100%;

      .plf-current-time-slot.plf-red {
        padding: 20px 0 0;
      }
    }
  }
}
