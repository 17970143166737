.plf-header .plf-header-metanav {
  @include valign;

  ul {
    @include flatten-list;

    li {
      padding-right: 10px;

      &,
      i,
      a {
        color: $color-gray;
      }

      &:first-of-type,
      &:hover {
        &,
        i,
        a {
          color: $color-red-dark;
          cursor: pointer;
        }
      }
    }
  }
}

//on collapse trigger click
.plf-header[data-clicked='true'] .plf-header-metanav {
  display: none;
}
