.plf-header .plf-header-collapse-trigger {
  div {
    @include valign;
    height: 100%;
  }

  button {
    @include valign;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
  }

  //dirty fix alignment of icon
  i {
    font-size: 24px;
    left: -5px;
    position: relative;
    top: 3px;
  }
}

.plf-header[data-clicked='true'] .plf-header-collapse-trigger {
  display: block;

  button {
    i {
      color: $color-red-dark;

      &::before {
        content: '\2a';
      }
    }

    span {
      display: none;
    }
  }
}
