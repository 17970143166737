$font-path: '../../fonts';

@font-face {
  font-family: 'sbbicons';
  font-style: normal;
  font-weight: normal;
  src: url($font-path + '/sbbicons/sbbicons.eot');
  src: url($font-path + '/sbbicons/sbbicons.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/sbbicons/sbbicons.woff') format('woff'),
    url($font-path + '/sbbicons/sbbicons.ttf') format('truetype'),
    url($font-path + '/sbbicons/sbbicons.svg#sbbicons') format('svg');
}

[data-icon]::before {
  content: attr(data-icon);
  font-family: 'sbbicons' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

[class^='icon-']::before,
[class*=' icon-']::before {
  font-family: 'sbbicons' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

.icon-010-achtung-001::before {
  content: '\61';
}

.icon-010-alarm-019::before {
  content: '\62';
}

.icon-010-bearbeiten-016::before {
  content: '\63';
}

.icon-010-benachrichtigung-015::before {
  content: '\64';
}

.icon-010-bild-147::before {
  content: '\65';
}

.icon-010-brieftasche-146::before {
  content: '\66';
}

.icon-010-browser-159::before {
  content: '\67';
}

.icon-010-bueroklammer-121::before {
  content: '\68';
}

.icon-010-datenbank-171::before {
  content: '\69';
}

.icon-010-diagramm-163::before {
  content: '\6a';
}

.icon-010-download-027::before {
  content: '\6b';
}

.icon-010-e-mail-003::before {
  content: '\6c';
}

.icon-010-einstellungen-014::before {
  content: '\6d';
}

.icon-010-favorit-005::before {
  content: '\6e';
}

.icon-010-fehler-126::before {
  content: '\6f';
}

.icon-010-filter-024::before {
  content: '\70';
}

.icon-010-filter-regler-029::before {
  content: '\71';
}

.icon-010-geschenk-033::before {
  content: '\72';
}

.icon-010-herz-031::before {
  content: '\73';
}

.icon-010-hilfe-kreis-137::before {
  content: '\74';
}

.icon-010-information-011::before {
  content: '\77';
}

.icon-010-kaffe-023::before {
  content: '\78';
}

.icon-010-kalender-006::before {
  content: '\79';
}

.icon-010-kamera-203::before {
  content: '\7a';
}

.icon-010-kategorie-030::before {
  content: '\41';
}

.icon-010-kontakt-166::before {
  content: '\42';
}

.icon-010-link-152::before {
  content: '\43';
}

.icon-010-navigation-017::before {
  content: '\44';
}

.icon-010-news-175::before {
  content: '\45';
}

.icon-010-papierkorb-008::before {
  content: '\46';
}

.icon-010-pin-148::before {
  content: '\47';
}

.icon-010-restaurant-022::before {
  content: '\48';
}

.icon-010-senden-142::before {
  content: '\49';
}

.icon-010-suche-009::before {
  content: '\4a';
}

.icon-010-upload-028::before {
  content: '\4b';
}

.icon-010-warenkorb-004::before {
  content: '\4c';
}

.icon-010-wifi-020::before {
  content: '\4d';
}

.icon-020-aendere-reihenfolge-125::before {
  content: '\4e';
}

.icon-020-aktualisieren-002::before {
  content: '\4f';
}

.icon-020-laden-007::before {
  content: '\50';
}

.icon-020-pfeil-klein-links-044::before {
  content: '\51';
}

.icon-020-pfeil-klein-rauf::before {
  content: '\52';
}

.icon-020-pfeil-klein-rechts-045::before {
  content: '\53';
}

.icon-020-pfeil-klein-runter-043::before {
  content: '\54';
}

.icon-020-pfeil-rechts-037::before {
  content: '\55';
}

.icon-020-pfeil-links-kreis-041::before {
  content: '\56';
}

.icon-020-pfeil-rauf-kreis-046::before {
  content: '\57';
}

.icon-020-pfeil-rechts-kreis-042::before {
  content: '\58';
}

.icon-020-pfeil-unten-kreis-040::before {
  content: '\59';
}

.icon-020-pfeil-vor-038::before {
  content: '\5a';
}

.icon-020-pfeil-vor-und-zurueck-039::before {
  content: '\30';
}

.icon-020-pfeil-zurueck-036::before {
  content: '\31';
}

.icon-020-rundreise-047::before {
  content: '\32';
}

.icon-020-vergroessern-144::before {
  content: '\33';
}

.icon-020-verkleinern-145::before {
  content: '\34';
}

.icon-030-anschalten-053::before {
  content: '\35';
}

.icon-030-ausrufezeichen-035::before {
  content: '\36';
}

.icon-030-entsperrt-113::before {
  content: '\37';
}

.icon-030-fragezeichen-115::before {
  content: '\38';
}

.icon-030-gesperrt-114::before {
  content: '\39';
}

.icon-030-ok-112::before {
  content: '\21';
}

.icon-030-pruefung-168::before {
  content: '\22';
}

.icon-030-sichtbar-096::before {
  content: '\23';
}

.icon-030-unsichtbar-095::before {
  content: '\24';
}

.icon-040-drei-punkte-menu-106::before {
  content: '\25';
}

.icon-040-hamburgermenu-102::before {
  content: '\26';
}

.icon-040-home-101::before {
  content: '\27';
}

.icon-040-minus-105::before {
  content: '\28';
}

.icon-040-plus-103::before {
  content: '\29';
}

.icon-040-schliessen-104::before {
  content: '\2a';
}

.icon-050-daumen-hoch-052::before {
  content: '\2b';
}

.icon-050-daumen-runtern-060::before {
  content: '\2c';
}

.icon-050-gluehbirne-an-048::before {
  content: '\2d';
}

.icon-050-gluehbirne-aus-049::before {
  content: '\2e';
}

.icon-050-handschlag-058::before {
  content: '\2f';
}

.icon-050-netzwerk-055::before {
  content: '\3b';
}

.icon-050-share-025::before {
  content: '\3c';
}

.icon-050-sprechblase-2-051::before {
  content: '\3d';
}

.icon-050-sprechblase-3-leer-054::before {
  content: '\40';
}

.icon-050-sprechblase-050::before {
  content: '\5b';
}

.icon-060-berechtigung-162::before {
  content: '\5d';
}

.icon-060-geburtstag-094::before {
  content: '\5e';
}

.icon-060-user-2-093::before {
  content: '\5f';
}

.icon-060-user-3-gruppe-057::before {
  content: '\60';
}

.icon-060-user-3-reihe-056::before {
  content: '\7b';
}

.icon-060-user-092::before {
  content: '\7c';
}

.icon-060-user-auswechslung-097::before {
  content: '\7e';
}

.icon-060-user-schulung-169::before {
  content: '\5c';
}

.icon-060-user-support-156::before {
  content: '\e000';
}

.icon-060-user-zugriff-124::before {
  content: '\e001';
}

.icon-070-dokument-bild-062::before {
  content: '\e002';
}

.icon-070-dokument-check-176::before {
  content: '\e003';
}

.icon-070-dokument-pdf-061::before {
  content: '\e004';
}

.icon-070-dokument-standard-067::before {
  content: '\e005';
}

.icon-070-dokument-text-063::before {
  content: '\e006';
}

.icon-070-dokument-ton-064::before {
  content: '\e007';
}

.icon-070-dokument-video-065::before {
  content: '\e008';
}

.icon-070-dokument-zip-066::before {
  content: '\e009';
}

.icon-070-ordner-018::before {
  content: '\e00a';
}

.icon-070-ordner-dateiablage-160::before {
  content: '\e00b';
}

.icon-070-ordner-hinzufuegen-130::before {
  content: '\e00c';
}

.icon-070-ordner-metadaten-131::before {
  content: '\e00d';
}

.icon-070-ordner-oeffnen-059::before {
  content: '\e00e';
}

.icon-080-auslastung-0-079::before {
  content: '\e00f';
}

.icon-080-fussweg-070::before {
  content: '\e010';
}

.icon-080-meine-reise-071::before {
  content: '\e011';
}

.icon-080-meine-reise-aus-072::before {
  content: '\e012';
}

.icon-080-perlschnur-ende-139::before {
  content: '\e013';
}

.icon-080-perlschnur-start-140::before {
  content: '\e014';
}

.icon-080-promocode-141::before {
  content: '\e015';
}

.icon-080-prozent-078::before {
  content: '\e016';
}

.icon-080-rollstuhl-073::before {
  content: '\e017';
}

.icon-080-rollstuhl-reservation-075::before {
  content: '\e018';
}

.icon-080-rollstuhl-teilweise-074::before {
  content: '\e019';
}

.icon-080-rollstuhl-unsicher-076::before {
  content: '\e01a';
}

.icon-080-rollstuhl-untauglich-077::before {
  content: '\e01b';
}

.icon-080-uhr-010::before {
  content: '\e01c';
}

.icon-080-wechsel-068::before {
  content: '\e01d';
}

.icon-080-zugformation-083::before {
  content: '\e01e';
}

.icon-090-billett-event-032::before {
  content: '\e01f';
}

.icon-090-billett-favorit-136::before {
  content: '\e020';
}

.icon-090-billett-kurzstrecke-143::before {
  content: '\e021';
}

.icon-090-billett-nicht-verfuegbar-138::before {
  content: '\e022';
}

.icon-090-billett-qrcode-026::before {
  content: '\e023';
}

.icon-090-billett-tageskarte-135::before {
  content: '\e024';
}

.icon-090-billette-sbb-069::before {
  content: '\e025';
}

.icon-100-bahnhof-117::before {
  content: '\e026';
}

.icon-100-couponing-134::before {
  content: '\e027';
}

.icon-100-geldwechsel-120::before {
  content: '\e028';
}

.icon-100-hostel-119::before {
  content: '\e029';
}

.icon-100-service-116::before {
  content: '\e02a';
}

.icon-100-treffpunkt-118::before {
  content: '\e02b';
}

.icon-110-gps-012::before {
  content: '\e02c';
}

.icon-110-gps-aus-013::before {
  content: '\e02d';
}

.icon-110-hoeher-123::before {
  content: '\e02e';
}

.icon-110-marker-098::before {
  content: '\e02f';
}

.icon-110-marker-a-099::before {
  content: '\e030';
}

.icon-110-marker-b-100::before {
  content: '\e031';
}

.icon-110-marker-in-der-naehe-107::before {
  content: '\e032';
}

.icon-110-marker-karte-173::before {
  content: '\e033';
}

.icon-110-tiefer-122::before {
  content: '\e034';
}

.icon-120-aktenkoffer-090::before {
  content: '\e036';
}

.icon-120-anschluesse-154::before {
  content: '\e037';
}

.icon-120-buerostuhl-087::before {
  content: '\e038';
}

.icon-120-drucker-021::before {
  content: '\e039';
}

.icon-120-funkgeraet-177::before {
  content: '\e03a';
}

.icon-120-kein-aktenkoffer-091::before {
  content: '\e03b';
}

.icon-120-laptop-167::before {
  content: '\e03c';
}

.icon-120-laptop-smartphone-165::before {
  content: '\e03d';
}

.icon-120-monitor-085::before {
  content: '\e03e';
}

.icon-120-scanner-178::before {
  content: '\e03f';
}

.icon-120-schreibtisch-088::before {
  content: '\e040';
}

.icon-120-schreibtisch-verstellbar-089::before {
  content: '\e041';
}

.icon-120-tastatur-086::before {
  content: '\e042';
}

.icon-120-telefon-festnetz-164::before {
  content: '\e043';
}

.icon-120-telefon-gsm-172::before {
  content: '\e044';
}

.icon-120-telefon-smartphone-084::before {
  content: '\e045';
}

.icon-130-facebook-108::before {
  content: '\e046';
}

.icon-130-googleplus-110::before {
  content: '\e047';
}

.icon-130-instagram-109::before {
  content: '\e048';
}

.icon-130-twitter-107::before {
  content: '\e049';
}

.icon-130-youtube-111::before {
  content: '\e04a';
}

.icon-140-bewoelkt-194::before {
  content: '\e04b';
}

.icon-140-bewoelkt-mit-sonne-186::before {
  content: '\e04c';
}

.icon-140-gewitter-202::before {
  content: '\e04d';
}

.icon-140-gewitter-bei-nacht-189::before {
  content: '\e04e';
}

.icon-140-graupel-196::before {
  content: '\e04f';
}

.icon-140-leicht-bewoelkt-197::before {
  content: '\e050';
}

.icon-140-mond-151::before {
  content: '\e051';
}

.icon-140-nebel-148::before {
  content: '\e052';
}

.icon-140-niesel-195::before {
  content: '\e053';
}

.icon-140-regen-199::before {
  content: '\e054';
}

.icon-140-regen-bei-nacht-190::before {
  content: '\e055';
}

.icon-140-regen-mit-sonne-198::before {
  content: '\e056';
}

.icon-140-schnee-201::before {
  content: '\e057';
}

.icon-140-schnee-gewitter-184::before {
  content: '\e058';
}

.icon-140-schnee-mit-sonne-193::before {
  content: '\e059';
}

.icon-140-schnee-mond-188::before {
  content: '\e05a';
}

.icon-140-schnee-sturm-200::before {
  content: '\e05b';
}

.icon-140-schneestern-185::before {
  content: '\e05c';
}

.icon-140-sonnig-149::before {
  content: '\e05e';
}

.icon-140-starker-regen-bei-nacht-191::before {
  content: '\e05f';
}

.icon-140-starker-regen-mit-sonne-192::before {
  content: '\e060';
}

.icon-140-stratus-150::before {
  content: '\e061';
}

.icon-140-wolke-mond-187::before {
  content: '\e062';
}
