.plf-button {
  @include more-info-button('.plf-button-inner-wrapper', '.plf-button-left-icon', '.plf-button-text', '.plf-button-right-icon');
  border-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-decoration: none;
  white-space: nowrap;

  i {
    font-size: 26px !important;
    position: relative;
    top: 7px;
  }

  span,
  span i {
    line-height: unset;
    transition: color 0.3s ease 0.2s;
  }

  &:hover {
    span,
    span i {
      color: $color-red-dark;
    }
  }

  &.plf-previous {
    .plf-button-inner-wrapper {
      &:hover {
        transform: translateX(0);
      }
    }
  }

  &.plf-button-big {
    background-color: $color-sbb-red;
    border-radius: 2px;
    display: block;
    height: 40px;
    line-height: $font-default-line-height;
    margin: 0 0 25px;
    padding: 0 15px;
    transition: background 0.3s ease;
    width: 100%;

    * {
      color: $color-white;
      line-height: unset;
    }

    .plf-button-text {
      margin-top: 10px;
    }

    i {
      font-size: 22px !important;
      margin-bottom: 10px;
      margin-left: 31px;
      top: 5px;
    }
      
    .plf-button-left-icon i {
      margin-bottom: 20px;
    }

    span {
      font-weight: 300;
      letter-spacing: 1px;
    }

    .plf-button-right-icon {
      float: right;
    }

    &:hover {
      background-color: $color-red-dark;

      * {
        color: $color-white;
      }
    }
  }
}


.plf-button-previous {
  @include more-info-button-previous('.plf-button-inner-wrapper', '.plf-button-left-icon', '.plf-button-text', '.plf-button-right-icon');
  border-bottom: 0;
  text-decoration: none;
  white-space: nowrap;

  .plf-button-text {
    margin-top: 10px;
  }
  
  .plf-button-left-icon i {
    margin-bottom: 20px;
  }

  i {
    font-size: 26px !important;
    margin-left: -31px;
    position: relative;
    top: 7px;
  }

  span,
  span i {
    transition: color 0.3s ease 0.2s;
  }

  &:hover {
    span,
    span i {
      color: $color-red-dark;
    }
  }

  &.plf-button-big {
    background-color: $color-sbb-red;
    border-radius: 2px;
    display: block;
    height: 40px;
    line-height: unset;
    margin: 0 0 25px;
    padding: 0 15px;
    transition: background 0.3s ease;
    width: 100%;

    .plf-button-right-icon {
      left: 0;
      position: absolute;
    }

    * {
      color: $color-white;
      line-height: unset;
    }

    i {
      font-size: 22px !important;
      top: 5px;
    }

    span {
      font-weight: 200;
      letter-spacing: 1px;
    }

    &:hover {
      background-color: $color-red-dark;

      * {
        color: $color-white;
      }
    }
  }
}
