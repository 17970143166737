.plf-header .plf-header-nav {
  height: 100%;
  //position:relative;

  ul {
    @include flatten-list;
    bottom: 0;
    position: absolute;

    li {
      margin-right: 40px;

      a {
        color: $color-gray-dark;
        display: block;
        font-size: 18px;
        padding-bottom: 25px;
        position: relative;
        text-decoration: none;
        transition: color 0.2s ease 0.2s;

        &:hover {
          color: $color-red-dark;

          &::after {
            left: 0;
            right: 0;
          }
        }

        &::after {
          background-color: $color-red-dark;
          bottom: 0;
          content: ' ';
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          right: 50%;
          transition: left 0.2s ease 0.2s, right 0.2s ease 0.2s;
        }
      }

      &[data-active='true'] {
        a {
          transition: none;

          &::after {
            background-color: $color-black;
            left: 0;
            right: 0;
          }

          &:hover::after {
            background-color: $color-red-dark;
          }
        }
      }

    }
  }
}
