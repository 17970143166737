form {
  label {
    display: block;
  }

  input,
  textarea,
  select {
    line-height: 30px;
    width: 100%;
  }
}

.plf-reservation-personal-information {
  .language-field select {
    border: 2px solid $color-ctrl-border;
    height: 50px;
    line-height: 50px;
  }
}
