footer .plf-langswitch {
  div {
    @include valign;
    height: 100%;
  }

  ul {
    @include flatten-list;

    li {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }

      span {
        color: $color-gray;
        font-size: 12px;
        text-transform: lowercase;
      }

      a {
        text-decoration: none;

        &:hover {
          border-bottom-color: $color-red-dark !important;
          color: $color-red-dark;
        }
      }

      &[data-selected='true'] {
        a {
          border-bottom: 1px solid $color-gray;
        }
      }
    }
  }
}
