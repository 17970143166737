//-------------------------------------------------------------------
// Color settings
//-------------------------------------------------------------------
$color-sbb-gray-dark: #3d3d3d;
$color-sbb-gray: #666;
$color-sbb-gray-middle: #a5a4a4;
$color-sbb-gray-light: #ededed;
$color-sbb-white: #fff;
$color-sbb-blue-light: #d9e0f0;
$color-sbb-orange: #ff9c00;
$color-sbb-red: #d70000; // old: E30613
$color-sbb-blue: #039;
$color-sbb-black: #000;

$color-sbb-default-text: $color-sbb-black;

$header-bg: rgba($color-sbb-white, 0.95);
$header-color: $color-sbb-black;

$sidebar-bg: $color-sbb-gray-light;
$sidebar-color: $color-sbb-black;

$article-bg: none;
$article-color: $color-sbb-black;

$dashboard-bg: $color-sbb-gray-light;
$dashboard-color: $color-sbb-red;

$footer-bg: $color-sbb-gray-light;
$footer-color: $color-sbb-black;

