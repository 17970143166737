$font-path: '../../fonts';

@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url($font-path + '/icomoon/icomoon.eot');
  src:    url($font-path + '/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
    url($font-path + '/icomoon/icomoon.ttf') format('truetype'),
    url($font-path + '/icomoon/icomoon.woff') format('woff'),
    url($font-path + '/icomoon/icomoon.svg#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-x::before {
  content: '\e900';
}

.icon-plus::before {
  content: '\e901';
}

.icon-pin::before {
  content: '\e902';
}

/* stylelint-disable-next-line */
.icon-pfeil_rechts::before {
  content: '\e903';
}

/* stylelint-disable-next-line */
.icon-pfeil_links::before {
  content: '\e904';
}

.icon-minus::before {
  content: '\e905';
}

.icon-listenansicht::before {
  content: '\e906';
}

.icon-kartenansicht::before {
  content: '\e907';
}
