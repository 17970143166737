@import 'up/up';
@import 'footer-main/footer-main';
@import 'footer-bottom/footer-bottom';

footer {
  .plf-footer-main,
  .plf-footer-main-trigger:checked ~ .plf-footer-main {
    height: $footer-main-height;
    padding: 70px 0;
  }

  &[data-fixed='true'] {
    .plf-footer-main-trigger-label {
      display: block;
    }

    .plf-footer-main {
      bottom: $footer-bottom-height;
      height: $footer-main-fixed-height;
      left: 0;
      padding: 0;
      position: fixed;
      right: 0;

      .plf-footer-main-content-wrapper {
        opacity: 0;
      }
    }

    .plf-up-outer-wrapper {
      padding-bottom: $footer-bottom-height + $footer-main-fixed-height + 75px;
    }

    .plf-up-wrapper[data-fixed='true'] {
      bottom: $footer-bottom-height + $footer-main-fixed-height + 75px;
      position: fixed;
      width: 100%;
    }
  }

  //main trigger logic
  .plf-footer-main-trigger {
    display: none;

    &:checked {
      ~ .plf-footer-main {
        .plf-footer-main-trigger-label {
          margin-top: -55px;
          transform: rotate(180deg);
        }

        .plf-footer-main-content-wrapper {
          opacity: 1;
        }
      }

      ~ .plf-up-wrapper {
        bottom: $footer-bottom-height + $footer-main-height;
      }
    }
  }

  //main trigger label style
  .plf-footer-main-trigger-label {
    display: none;
    height: 40px;
    margin-bottom: 0;
    text-align: center;
    transition: transform 0.3s ease;
    width: 100%;

    i {
      color: $color-gray-dark;
      font-size: 26px;
      line-height: 40px;
      position: relative;
      top: 5px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);

      i {
        color: $color-red-dark;
      }
    }
  }
}

//tablet
@media (max-width: $screen-sm-max) {
  footer {
    //reset footer heights
    .plf-footer-main,
    .plf-footer-bottom {
      height: auto;
    }
  }
}

