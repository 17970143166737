.sv-login {
  background-color: $color-sbb-gray-light;
  color: $color-sbb-default-text;
  font-size: $font-size-login;
  margin: rem-calc(40) auto rem-calc(40) auto;
  max-width: 80%;
  padding: rem-calc(20);
  width: rem-calc(600);

  .sv-admin-section-title {
    color: $color-sbb-red;
    font-size: $font-size-login-title;
    margin-bottom: rem-calc(20);
  }

  .sv-save {
    float: right;
    padding: rem-calc(15);
  }

  .login-button-box {
    line-height: rem-calc(40);
    vertical-align: middle;

    .sv-button-style {
      background-color: $color-sbb-red;
      color: $color-white;
      padding: rem-calc(10);

      &:hover {
        background-color: $color-sbb-gray;
      }
    }
  }

  p.sv-admin-section-content {
    display: inline-block;
    margin-top: rem-calc(40);
    width: 100%;
  }
}
