.plf-content-wrapper {
  background-color: $color-sbb-white;
  color: $article-color;

  font-size: 14px;
  margin: 20px auto;

  // FIXME: This is temporary
  max-width: 100%;

  min-height: 100%;
  padding: 20px 8px;

  .plf-content {
    min-height: 100%;

    a {
      color: $color-sbb-default-text;
      font-weight: $font-weight-normal;

      &:hover {
        border-bottom-color: $color-sbb-red;
        color: $color-sbb-red;
      }

      &:focus {
        outline: 0;
      }
    }

    h1 {

      margin-bottom: 15px;
      margin-top: 0;

      &,
      * {
        font-size: 40px;
        font-weight: $font-weight-light;
        line-height: 1.2;
      }
    }

    h2 {
      margin-bottom: 24px;

      margin-top: 40px;

      &,
      * {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.3;
      }

      // TODO
      //@include breakpoint(small only) {
      //font-size: $fontSizeText - rem-calc(4);
      //}
    }

    h3 {
      margin-bottom: 24px;

      margin-top: 40px;

      &,
      * {
        font-size: 22px;
        font-weight: $font-weight-bold;
        line-height: 1.3;
      }
      //border-bottom: rem-calc(1) solid $colorSBBRed;

      // TODO
      //@include breakpoint(small only) {
      //  font-size: $fontSizeText - rem-calc(4);
      //}
    }

    .plf-reservation-stop-image-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 550px;
      margin-bottom: 40px;
      transition: height 0.2s;
      width: 100%;

      @media screen and (max-width: $screen-md-max) {
        height: 350px;
      }

      @media screen and (max-width: $screen-sm-max) {
        height: 220px;
      }
    }

    label,
    select {
      font-size: 14px;
    }

    select {
      background-color: $color-white;
      border: 2px solid $color-ctrl-border;
      height: 50px;
      margin-bottom: 1rem;
      padding: 0 5px;
    }

    .ym-error {
      input {
        border: 1px solid $color-sbb-red;
      }

      .ym-message {
        color: $color-sbb-red;
      }
    }

    form .plf-form-error {
      input,
      select {
        border: 2px solid $color-sbb-red;
      }

      //label {
      //  color: $colorSBBRed;
      //}
    }

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
      appearance: none;
      appearance: none;
      background-color: #fefefe;
      border: 2px solid $color-ctrl-border;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;
      color: #0a0a0a;
      display: block;
      font-family: inherit;
      font-size: 14px;
      height: 50px;
      line-height: 1;
      margin: 0 0 1rem;
      outline: 0;
      padding: 0 10px;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      width: 100%;
    }

    textarea {
      padding: 10px;
    }

    :input-placeholder {
      color: #666;
    }

    input::-ms-clear {
      display: none;
    }

    .plf-reservation-navigation {
      clear: both;
      padding-top: 50px;
      position: relative;

      .right {
        .plf-button.plf-button-big {
          float: right;
        }
      }

      .plf-button,
      .plf-button-previous {
        &.plf-button-big {
          display: inline-block;
          width: auto;

          .plf-button-inner-wrapper {
            .plf-button-text {
              padding-right: 26px;
            }
          }
        }
      }

      .plf-button-previous {
        background: $color-gray-almost-dark;

        &:hover {
          background: $color-red-dark;
        }
      }

      @media screen and (max-width: $screen-xs-max) {
        .plf-button,
        .plf-button-previous {
          &.plf-button-big {
            display: block;
          }
        }

        .right {
          .plf-button.plf-button-big {
            float: none;
          }
        }
      }
    }

    .row::before,
    .row::after {
      display: inline-block;
    }

    .row .plf-columns-no-padding .columns {
      padding-left: 0;
      padding-right: 0;
    }

    .plf-padding-25 {
      padding: 25px !important;
    }

    .plf-padding-10 {
      padding: 10px !important;
    }

    /* stylelint-disable-next-line */
    .radio_list {
      margin-bottom: 25px;
      overflow: hidden;

      div {
        $checkbox-width: 25px;
        float: left;
        margin-right: 25px;

        input {
          width: auto;

          &[type='radio'] {
            display: inline-block;
            margin: 6px 0 0;
            position: absolute;

            &:checked,
            &:focus {
              background: $color-sbb-red;
            }
          }
        }

        label {
          display: inline-block;
          line-height: $checkbox-width;
          margin-bottom: 5px;
          padding-left: $checkbox-width;
          position: relative;
          vertical-align: top;
        }

        &:hover {
          color: $color-sbb-coral;

          input {
            border-color: $color-sbb-coral;
          }
        }
      }
    }

    .plf-reservation-stop-title,
    .plf-current-time-slot {
      &.plf-red {
        border: 0;
        border-radius: 2px;
        line-height: 40px;
        padding: 0 20px;
        position: relative;

        h3 {
          margin-top: 0;
        }

        p {
          font-size: large;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    .plf-info-textarea-container textarea {
      height: 90px;
    }

    .plf-needstickets-title {
      display: inline-block;
      margin-bottom: 15px;
      margin-top: 20px;
    }

    .plf-lieferadresse-title {
      display: inline-block;
      margin-bottom: 15px;
    }

    .plf-table {
      tbody {

        background-color: $color-white;
        border: 0;

        tr:nth-child(even) {
          background-color: $color-white;
        }

        td {
          padding-bottom: 2px;
        }
      }

      &.plf-overview-table {
        font-size: 18px;
        line-height: 30px;
        width: 100%;

        tr {
          td {
            vertical-align: top;

            &:first-child {
              width: 220px;
            }
          }

          &:nth-child(1),
          &:nth-child(2) {
            td:last-child {
              padding-bottom: 20px;
            }
          }

          &:last-child td:last-child {
            padding-bottom: 2px;
          }
        }

        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 20px;
        }

        @media screen and (max-width: $screen-xs-max) {
          tr {
            height: auto;
            margin: 0 -10px;
            padding: 4px 10px;

            &,
            td {
              display: block;
            }

            &:nth-child(odd) {
              background: $color-gray-lighter;
            }

            &:nth-child(1),
            &:nth-child(2) {
              background: transparent;
            }
          }
        }
      }
    }

    .plf-form-required {
      label::after {
        content: ' *';
      }
    }

    .plf-overview-title {
      display: block;

      margin-bottom: 8px;
    }

    @import 'content-slots/choose-stop';
    @import 'content-slots/choose-date';
  }
}

