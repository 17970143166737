footer .plf-footer-main .plf-footer-main-content .plf-content-toggable-area {
  p,
  a,
  span {
    font-size: 14px !important;
  }

  a {
    border-bottom: 1px solid $color-gray;
    text-decoration: none;

    &:hover {
      border-bottom-color: $color-red-dark;

      &,
      span {
        color: $color-red-dark;
      }
    }
  }

  ul.plf-footer-linklist {
    @include flatten-list(true);

    li:not(:last-of-type) {
      padding-bottom: 10px;
    }
  }

  .plf-button {
    @include more-info-button('.plf-button-inner-wrapper', '.plf-button-left-icon', '.plf-button-text', '.plf-button-right-icon');
    border-bottom: 0;
    text-decoration: none;

    i {
      font-size: 26px !important;
      position: relative;
      top: 7px;
    }

    span,
    span i {
      transition: color 0.3s ease 0.2s;
    }

    &:hover {
      span,
      span i {
        color: $color-red-dark;
      }
    }

    &.plf-button-big {
      background-color: $color-gray-almost-dark;
      border-radius: 2px;
      display: block;
      height: 40px;
      margin: 0 0 25px;
      padding: 0 15px;
      transition: background 0.3s ease;
      width: 100%;

      * {
        color: $color-white;
      }

      i {
        font-size: 22px !important;
        top: 5px;
      }

      span {
        font-weight: 200;
        letter-spacing: 1px;
      }

      .plf-button-right-icon {
        float: right;
      }

      &:hover {
        background-color: $color-red-dark;

        * {
          color: $color-white;
        }
      }
    }
  }

  ul.plf-footer-social-list {
    @include flatten-list;
    margin-top: 20px;

    li {
      margin-bottom: 16px;
      margin-right: 8px;
    }

    .plf-footer-social-icon {
      background-color: $color-gray-almost-dark;
      border-radius: 50%;
      display: block;
      height: 40px;
      text-align: center;
      text-decoration: none;
      transition: background 0.3s ease;
      width: 40px;

      i {
        color: $color-white;
        font-size: 28px;
        position: relative;
        top: 6px;
      }

      &:hover {
        background-color: $color-red-dark;
      }
    }
  }
}
