.plf-header .plf-header-brand {
  div {
    @include valign;
    height: 100%;
    table-layout: fixed;
    width: 100%;
  }

  div {
    text-align: right;
  }

  picture,
  img {
    max-width: 100%;
  }
}
