.plf-header .plf-sidebar-trigger {
  div {
    @include valign;
    height: 100%;
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    padding: 0;

    //dirty fix alignment of icon
    i {
      left: -5px;
      position: relative;
      top: 3px;
    }

    &[data-open='true'] i::before {
      content: '\2a';
    }
  }
}
