@mixin more-info-button($wrap, $left-icon, $text, $right-icon) {
  $icon-width: 26px;
  $text-side-padding: 5px;

  #{$wrap} {
    transition: 0.3s ease 0.2s;

    * {
      display: inline-block;
      transform: translateX(-($icon-width + $text-side-padding));
      transition: 0.3s ease 0.2s;
    }

    i {
      font-weight: 100;
    }

    #{$left-icon},
    #{$right-icon} {
      overflow: visible;
      width: 0;

      i {
        width: $icon-width;
      }
    }

    #{$left-icon} {
      opacity: 0;
    }

    #{$right-icon} {
      opacity: 1;
    }

    #{$text} {
      max-width: calc(100% - #{$icon-width});
      padding-left: $icon-width;
      padding-right: $text-side-padding;
    }

    &:hover {
      transform: translateX($icon-width);
    }
  }

  &:hover {
    cursor: pointer;

    #{$left-icon} {
      opacity: 1;
      transform: translateX(0);
    }

    #{$right-icon} {
      opacity: 0;
      transform: translateX(0);
    }

    #{$text} {
      transform: translateX(0);
    }
  }
}
