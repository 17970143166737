.plf-checkbox-custom,
.plf-radio-custom {
  opacity: 0;
  position: absolute;
}

.plf-checkbox-custom,
.plf-checkbox-custom-label,
.plf-radio-custom,
.plf-radio-custom-label {
  cursor: pointer;
  display: inline-block;
  margin: rem-calc(5);
  text-align: center;
  vertical-align: middle;
}

.plf-checkbox-custom-label,
.plf-radio-custom-label {
  position: relative;
  width: 100%;

  span {
    display: inline-block;
    line-height: rem-calc(25);
    max-width: 95%;

    // TODO
    //@include breakpoint(small only){
    //  max-width: 85%;
    //}
  }
}

.plf-checkbox-custom + .plf-checkbox-custom-label::before,
.plf-radio-custom + .plf-radio-custom-label::before {
  background: $color-white;
  border: rem-calc(2) solid #ddd;
  content: '';
  display: inline-block;
  height: rem-calc(20);
  margin-top: rem-calc(2);
  padding: rem-calc(2);
  //margin-right: rem-calc(10);
  text-align: center;
  vertical-align: top;
  width: rem-calc(20);
}

.plf-checkbox-custom:checked + .plf-checkbox-custom-label::before {
  background: $color-sbb-gray;
  color: $color-white;
  content: '\f00c';
  font-family: 'FontAwesome';
  line-height: 100%;
  text-align: center;
  vertical-align: top;
}

.plf-radio-custom + .plf-radio-custom-label::before {
  border-radius: 50%;
}

.plf-radio-custom:checked + .plf-radio-custom-label::before {
  color: $color-sbb-gray-light;
  content: '\f00c';
  font-family: 'FontAwesome';
  line-height: 100%;
  text-align: center;
  vertical-align: top;
}

.plf-checkbox-custom:focus + .plf-checkbox-custom-label,
.plf-radio-custom:focus + .plf-radio-custom-label {
  outline: rem-calc(1) solid $color-sbb-gray-light; /* focus style */
}
