// Page not found
//-------------------------------------------------------------------
.plf-page-not-found {

  background: $color-sbb-gray-light;
  margin: rem-calc(20) + $header-height auto rem-calc(20) auto;
  padding: rem-calc(20);

  width: 50%;

  .plf-404-wrapper {
    padding: rem-calc(20);
  }

  .plf-404-title {
    color: $color-sbb-red;
    font-size: $font-size-404-title !important;
    font-weight: $font-weight-light !important;
    margin-bottom: rem-calc(20) !important;
  }

  .plf-404-text {
    font-size: $font-size-404 !important;
  }

  // TODO
  /*@include breakpoint(small only) {
    width: 100% !important;
    padding: $sideMarginSmall !important;
    background-color: $colorWhite;

    .plf-404-wrapper {
      padding: rem-calc(10);
    }

    .plf-404-title {
      font-size: $fontSize404Title - rem-calc(8) !important;
    }

    .plf-404-text {
      font-size: $fontSize404 - rem-calc(2) !important;
    }
  }*/
}
