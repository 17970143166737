.plf-survey-wrapper {
  display: block;
  margin: rem-calc(20) auto;

  // General style
  .plf-last-survey-answer,
  .plf-survey-question,
  .plf-actual-survey-answer {
    display: block;
    font-size: $font-size-survey;
    padding: rem-calc(30) rem-calc(20);

    .plf-survey-title {
      display: block;
      font-size: $font-size-survey-title;
      font-weight: $font-weight-bold;
      margin: rem-calc(10) auto rem-calc(20) auto;
    }
  }

  // Survey style
  .plf-survey-question {
    background-color: $color-sbb-gray-light;

    .plf-survey-title {
      color: $color-sbb-default-text;
    }

    .plf-checkbox-custom-label {
      font-size: $font-size-survey;
      line-height: 100%;
      margin-bottom: rem-calc(3);
      text-align: left;
      vertical-align: middle;

      &::before {
        font-size: rem-calc(13);
      }
    }

    .plf-checkbox-wrapper {
      line-height: rem-calc(40);
    }
  }

  // Result style
  .plf-last-survey-answer,
  .plf-actual-survey-answer {
    &.row {
      padding: rem-calc(30) rem-calc(20);
    }

    .plf-survey-title {
      display: block;
    }

    .plf-survey-previous {
      display: block;
      font-weight: $font-weight-bold;
    }

    .plf-survey-count {
      display: block;
      font-size: $font-size-survey - rem-calc(4);
      font-weight: $font-weight-light;
      margin: rem-calc(5) auto rem-calc(20) auto;
      padding-right: rem-calc(20);
      text-align: right;
    }

    .plf-survey-result {
      counter-reset: list;

      margin: 0 0 0 1.4em;

      .plf-result {
        line-height: rem-calc(25);
        list-style: none;
        margin-bottom: rem-calc(10);
        position: relative;

        &::before {
          content: counter(list, lower-alpha) ') ';
          counter-increment: list;
          left: -1.4em;
          position: absolute;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .plf-survey-graph-wrapper {
      padding: rem-calc(20);

      .plf-survey-graph {
        display: block;
        height: 100%;
        text-align: center;
        width: 100%;

        .plf-graph-wrapper {
          display: inline-block;
          height: 100%;
          margin-right: 10%;
          position: relative;
          width: 10%;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .plf-graph {
    bottom: 0;
    display: block;
    font-weight: $font-weight-bold;
    position: absolute;
    text-align: center;
    vertical-align: bottom;
    vertical-align: text-bottom;
    width: 100%;

    .plf-graph-content {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .plf-graph-tooltip {
      display: none;
      font-size: $font-size-survey - rem-calc(4);
      padding: rem-calc(5);
      position: absolute;
      text-align: center;
      top: - rem-calc(40);
      vertical-align: middle;
    }

    &:hover {
      .plf-graph-tooltip {
        display: block;
      }
    }
  }

  .plf-last-survey-answer {
    background-color: $color-sbb-red;
    color: $color-white;

    .plf-survey-graph-wrapper {
      .plf-survey-graph {
        border-bottom: rem-calc(1) solid $color-white;


        .plf-graph {
          background-color: $color-white;
          color: $color-sbb-red;

          .plf-graph-tooltip {
            background-color: $color-white;
            color: $color-sbb-default-text;

            &::after {
              border-left: solid rem-calc(8) transparent;
              border-right: solid rem-calc(8) transparent;
              border-top: solid rem-calc(8) $color-white;
              content: '';
              position: absolute;
              right: 50%;
              top: 100%;
            }
          }

          &.plf-user-vote {
            background-color: $color-sbb-red;
            border: rem-calc(1) solid $color-white;
            border-bottom: 0;
            color: $color-white;
          }
        }
      }
    }
  }

  .plf-actual-survey-answer {
    background-color: $color-sbb-gray-light;
    color: $color-sbb-default-text;

    .plf-anchor {
      color: $color-sbb-red;
    }

    .plf-survey-graph-wrapper {
      .plf-survey-graph {
        border-bottom: rem-calc(1) solid $color-sbb-red;


        .plf-graph {
          background-color: $color-sbb-red;
          color: $color-white;

          .plf-graph-tooltip {
            background-color: $color-sbb-red;
            color: $color-white;

            &::after {
              border-left: solid rem-calc(8) transparent;
              border-right: solid rem-calc(8) transparent;
              border-top: solid rem-calc(8) $color-sbb-red;
              content: '';
              position: absolute;
              right: 50%;
              top: 100%;
            }
          }

          &.plf-user-vote {
            background-color: $color-white;
            border: rem-calc(1) solid $color-sbb-red;
            border-bottom: 0;
            color: $color-sbb-red;
          }
        }
      }
    }
  }
}
