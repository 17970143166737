$color-sbb-white: #fff;
$color-sbb-green: #349f1a;
$color-sbb-blue: #039;
$color-sbb-red: #e71e14;
$color-sbb-dark-gray: #a5a3a4;
$color-sbb-almost-gray: #a8a8a8;
$color-sbb-gray: #dbdbdb;
$color-sbb-light-gray: #ededed;
$color-sbb-yellow: #ffdb4c;
$color-sbb-orange: #ff9c00;
$color-sbb-block-selection: $color-sbb-red;
$color-sbb-coral: #ff7f50;
