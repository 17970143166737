@import 'langswitch/langswitch';
@import 'footer-bottom-content/footer-bottom-content';

footer .plf-footer-bottom {
  background-color: $color-white;
  height: $footer-bottom-height;

  .container-fluid,
  .row,
  .column {
    height: 100%;
  }
}

footer[data-fixed='true'] .plf-footer-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
}

//tablet
@media (max-width: $screen-sm-max) {
  footer .plf-footer-bottom {
    padding: 15px 0;

    ul li {
      line-height: 40px;
    }
  }
}

//mobile
@media (max-width: $screen-xs-max) {
  footer .plf-footer-bottom {
    .column {
      div,
      ul {
        width: 100%;
      }

      ul {
        text-align: center;
      }
    }

    .plf-footer-bottom-content ul li {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
