@import 'footer-main-content';

footer .row.plf-footer-main-content-wrapper {
  @media (min-width:$screen-md-min) {
    //fix row/col spacing
    margin: 0 -24px;

    .column {
      padding: 0 24px;
    }
  }
}

footer .plf-footer-main {
  background-color: $color-gray-light;
  transition: height 0.3s ease;

  * {
    color: $color-gray;
    font-size: 14px;
    line-height: 21px;
  }

  h2 {
    border-bottom: 1px solid $color-gray-medium;
    font-size: 16px;
    font-weight: normal;
    height: 50px;
    line-height: 27px;
    margin-bottom: 20px;
  }
}

//mobile/tablet accordions
@media (max-width: $screen-sm-max) {
  footer .plf-footer-main {
    padding: 20px 0 !important;

    //reset width spacings
    .container-fluid,
    .column {
      padding: 0;
    }

    .plf-footer-main-content {
      margin: 0;

      h2,
      .plf-content-toggable-area {
        padding: 0 25px;
      }
    }

    //styles
    .plf-content-trigger-label {
      border: 1px solid $color-almost-gray;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      float: right;
      height: 25px;
      margin-bottom: 0;
      text-align: center;
      width: 25px;

      i {
        color: $color-gray;
        font-size: 20px;
        position: relative;
        top: 2px;
      }
    }

    //trigger logic
    .plf-content-trigger:checked {
      .plf-content-toggable-area {
        display: block;
      }

      h2 label i::before {
        content: '\28';
      }
    }

    .plf-content-toggable-area {
      display: none;
      padding-bottom: 10px;
    }

    //improve last accordion element
    .column:last-of-type .plf-content-trigger:not(:checked) {
      h2 {
        border-bottom: 0;
        height: 30px;
        margin-bottom: 0;
      }
    }
  }
}
